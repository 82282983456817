import React from 'react'
import {ActionButton} from "../Button/Button";

export interface IPagerProps {
    currentPage: number,
    pageChanged: (currentPage: number)=>void
}

export function Pager(props: IPagerProps) {
    function incrementPage() {
        props.pageChanged(props.currentPage + 1);
    }

    function decrementPage() {
        if(props.currentPage > 0){
            props.pageChanged(props.currentPage - 1);
        }
    }

    return <div>
        <ActionButton onClick={decrementPage}>←</ActionButton>
        Strona: {props.currentPage}
        <ActionButton onClick={incrementPage}>→</ActionButton>
    </div>
}