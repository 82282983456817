import React, {useEffect} from 'react';
import styles from './App.module.css'
import {Title} from "./components/Title/Title";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {MainPage} from "./pages/MainPage";
import {PrivacyPage} from "./pages/PrivacyPage";
import {RankingPage} from "./pages/RankingPage";
import {Update1_10} from "./pages/Update1_10";

function App() {
    useEffect(() => {
        document.title = 'Symulator Prezydenta 3650'
    })

    return (
        <Router>
            <div className={styles.App}>
                <Title/>
                <Switch>
                    <Route exact path="/">
                        <MainPage/>
                    </Route>
                    <Route path="/privacy">
                        <PrivacyPage/>
                    </Route>
                    <Route path="/ranking">
                        <RankingPage/>
                    </Route>
                    <Route path="/update-1-10">
                        <Update1_10/>
                    </Route>
                </Switch>

            </div>
        </Router>
    );
}

export default App;
