import React from 'react';
import {PromoImg} from "../components/PromoImg/PromoImg";
import {Button, RouteButton} from "../components/Button/Button";
import styles from "./pages.module.css"

export function Update1_10(){
    return <div>
        <h1>Update 1.10</h1>
        <ul>
            <li>Ranking</li>
            <li>Kot prezesa</li>
            <li>Nieco szybsze ładowanie</li>
        </ul>
        <PromoImg url="/update_1_10_promo.jpg"/>
        <div className={styles.center} style={{marginTop: '20px', marginBottom: '20px'}}>
            <RouteButton to="/">Zagraj</RouteButton>
        </div>
    </div>
}