import React from 'react';
import styles from './Title.module.css';
import logo from './game_icon.png'

export function Title() {
    return (
        <h1 className={styles.Title}>
            <div><img src={logo}/></div>
            <div>Symulator Prezydenta 3650</div>
        </h1>
    )
}