import React from 'react';
import {RouteButton} from "../components/Button/Button";

export function PrivacyPage() {
    return (
        <div>
            <h2>Polityka Prywatności</h2>
            <p>Ta strona używa Google Analytics do zbierania anonimowych informacji o ilości odwiedzin.</p>
            <p>Zbieraniu nie podlegają dane osobowe użytkowników (np. adresy IP, User-ID), Google Analytics ma też wyłączone profilowanie reklam.</p>
            <p><RouteButton to="/">Powrót</RouteButton></p>
        </div>

    )
}
