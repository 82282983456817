import styles from "../App.module.css";
import {Button, RouteButton} from "../components/Button/Button";
import React from "react";

export function MainPage(){

    return (
        <div>
            <div className={styles.Description}>
                <p>Właśnie zostałeś wybranym na prezydenta całkowicie fikcyjnego kraju Cebulandii. Twoim zadaniem jest podpisywanie wszystkich ustaw, które podsuwa Ci prezes partii rządzącej (dlatego fikcyjnego kraju, bo w rzeczywistości żaden prezydent przecież tak nie robi).</p>

                <p>Podpisuj ustawy jak najszybciej i przyznawaj ordery żeby zadowolić prezesa. Zdobywaj ulepszenia długopisu. Pamiętaj jednak, że wredna opozycja podrzuca Ci do podpisania różne rzeczy niebędące ustawami. Uważaj żeby nie sygnować kalendarza, albo zwolnienia z W-Fu.</p>

                <p>Twoim celem jest przetrwanie dwóch kadencji czyli 3650 dni.</p>

                <p>Symulator Prezydenta to szybka i prosta gra zręcznościowa, polegająca na podpisywaniu ustaw w jak największych kombinacjach.</p>
            </div>
            <div className={styles.ButtonSectionWrapper}>
                <div className={styles.ButtonsSection}>
                    <Button href="/game/">graj</Button>
                    <Button href="https://play.google.com/store/apps/details?id=com.symulatorprezydenta.presidentsimulatorgodot">Android</Button>
                    <RouteButton to="/privacy">Prywatność</RouteButton>
                    <RouteButton to="/ranking">Ranking</RouteButton>
                </div>
            </div>
        </div>
    )

}