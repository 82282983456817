import React from 'react';
import styles from './Button.module.css';
import {Link} from "react-router-dom";

interface IButtonProps {
    href?: string
}

interface IRouteButtonProps {
    to: string
}

interface IActionButtonProps {
    onClick: ()=>void
}

export function Button(props: React.PropsWithChildren<IButtonProps>) {
    return (
        <a className={styles.Button} href={props.href}>
            {props.children}
        </a>
    );
}

export function RouteButton(props: React.PropsWithChildren<IRouteButtonProps>) {
    return (
        <Link className={styles.Button} to={props.to}>
            {props.children}
        </Link>
    );
}

export function ActionButton(props: React.PropsWithChildren<IActionButtonProps>) {
    return (
        <button className={styles.Button} onClick={props.onClick}>
            {props.children}
        </button>
    );
}