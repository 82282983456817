import React from 'react';
import styles from './PromoImg.module.css'

interface IPromoImgProps {
    url: string
}

export function PromoImg(props: IPromoImgProps){
    var style = {
        backgroundImage:  `url('${props.url}')`,
    } as React.CSSProperties

    return <div className={styles.PromoImg} style={style}>
    </div>
}