import React, {useEffect, useState} from 'react';
import {Pager} from "../components/Pager/Pager";
import {RouteButton} from "../components/Button/Button";

interface IRow {
    index: number;
    "uuid": string,
    "playerName": string,
    "score": number,
    "daysSurvived": number,
    "timeInSeconds": number,
    "bestCombo": number,
    "signedPagesCount": number,
    "category": 1,
    "isVisible": boolean,
    "createTime": string
}

const pageSize = 20;

export function RankingPage() {
    var [model, setModel] = useState<IRow[]>(new Array<IRow>());

    var [page, setPage] = useState(0);

    useEffect(()=> {
        fetch(`/api/results/1?page=${page}&pageSize=${20}`)
            .then(r => r.json())
            .then(r => {
                setModel(r as IRow[]);
            })
    }, [page])

    function renderRow(row: IRow) {
        const timeInSeconds = row.timeInSeconds % 60;
        const timeInMinutes = Math.trunc(row.timeInSeconds / 60);

        return <tr>
            <td>{row.index + 1}</td>
            <td>{row.playerName}</td>
            <td>{timeInMinutes}m {timeInSeconds}s</td>
            <td>{row.bestCombo}</td>
        </tr>
    }


    return (
        <div>
            <h2>Ranking najlepszych speedrunów</h2>
            <table>
                <thead>
                    <tr>
                        <th>
                            Miejsce
                        </th>
                        <th>
                            Nickname
                        </th>
                        <th>
                            Czas gry
                        </th>
                        <th>
                            Najlepsze combo
                        </th>
                    </tr>
                </thead>
                <tbody>
                {model.map(renderRow)}
                </tbody>
            </table>
            <Pager currentPage={page} pageChanged={setPage}/>
            <p><RouteButton to="/">Powrót</RouteButton></p>
        </div>
    )
}